import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBfa62SdnThVdXTTQD-cLXudPxam2agQn8",
    authDomain: "savingspree2.firebaseapp.com",
    databaseURL: "https://savingspree2.firebaseio.com"
});

const base = Rebase.createClass(firebaseApp.database());
export { firebaseApp };
export default base;