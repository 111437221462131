import React from 'react';
import StraightProgressbar from './StraightProgressbar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { formatThousands } from '../helpers';

const getColorForUrgency = urgency => {
    const timeUrgencyToColorsMap = {1:"#00D870", 0.25:"#FF9D00", 0.10:"#FF007C"};
    const allKeysUpToUrgencyLevel = Object.keys(timeUrgencyToColorsMap).filter(u => u >= urgency);
    const timeUrgencyAsKey = allKeysUpToUrgencyLevel[allKeysUpToUrgencyLevel.length-1];
    const color = timeUrgencyToColorsMap[timeUrgencyAsKey];
    return color;
}

const Gauge = props => {
    const { value, maxValue, label, isDollarAmount, isTimeUrgent } = props;
    const pathColor = isTimeUrgent ? getColorForUrgency(value / maxValue) : "#00D870";
    return (
        <div className="gauge">
            {props.indicator === "ring" ?
                <div className="circularProgressbar">
                    <CircularProgressbar
                        value={value}
                        maxValue={maxValue}
                        strokeWidth={10}
                        styles={buildStyles({
                            pathColor: pathColor,
                            trailColor: "#E6E6E6"
                        })}/>
                </div>
            :
                <div className="progressbar">
                    <StraightProgressbar
                        value={value}
                        maxValue={maxValue}
                        strokeWidth={10}
                        pathColor={pathColor}
                        trailColor="#E6E6E6"/>
                </div>
            }
            <h3 className="value">{isDollarAmount && '$'}{formatThousands(value)}</h3>
            <h3 className="label">{label}</h3>
        </div>
    )
}

export default Gauge;