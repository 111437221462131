import React from 'react';

const StraightProgressbar = props => {
    const { value, maxValue, strokeWidth, trailColor, pathColor } = props;
    const minDisplayValue = 210;
    const displayValue = (value > minDisplayValue) ? value : minDisplayValue;
    const pathPercent = displayValue / maxValue;
    return (
        <div className="trail" style={{width: '100%', height: strokeWidth + 'px', backgroundColor: trailColor}}>
            <div className="path" style={{width: (pathPercent * 100) + '%', height: '100%', backgroundColor: pathColor, visibility: value > 0 ? 'visible' : 'hidden' }}></div>
        </div>
    )
}

export default StraightProgressbar;