import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Status = props => {
    const statusLabels = [
        'at risk',
        'poor',
        'fair',
        'good',
        'spree'
    ];
    const status = statusLabels[Math.floor(props.level)];
    const isSpree = props.level >= 4;
    
    return (
        <header className={["status", status].join(" ")}>
            <div className="statusRing">
            <CircularProgressbar
                value={props.level}
                maxValue={5}
                circleRatio={0.59}
                strokeWidth={8}
                styles={buildStyles({
                    pathColor: "#FFFFFFFF",
                    trailColor: "#FFFFFF4C"
                })}/>
            </div>
            <span className={["statusValue", (isSpree ? 'italic': '')].join(" ")}>
                {status + (isSpree ? '!' : '')}
            </span>
            <span className="statusDescription">Savings Status</span>
        </header>
    )
}

export default Status;