import React from 'react';

const Row = props => (
    <div className="row">
        <div className="title">
            {props.title}
        </div>
        <div>
            {props.children}
        </div>
    </div>
)

export default Row;