
const user = {
    name: "Nathan Roberton",
    id: "pmbm45dv5",
    contact: {
        email: "nathan.roberton@me.com"
    },
    currency: "USD",
    utcOffset: -480,
    locale: "en-US",
    steps: [
        `Put away %{payDaySavingsAmount} on payday.`,
        `Save %{dailySavingsAmount} that you'd otherwise spend.`,
        `%{bestStrategy}`,
        `Invent and use your own trick to save %{weeklySavingsAmount}`,
        `%{bestStrategy}`,
        `Combine %{bestStrategyShortName} and saving leftover money on payday.`,
        `%{bestStrategy}`,
        `Cut a fixed expense over %{weeklySavingsAmount} save it and %{bestStrategyShortName}`,
        `%{bestStrategyShortName} while breaking down big-picture targets`,
        `%{usersBigPictureStrategy}`,
        `%{usersAutoSavingsStrategy} and %{bestStrategyShortName}`,
        `%{bestStrategyShortName} and %{nextBestStrategyShortName}`
    ],
    goals: [
        `Save %{goalAmount} in under %{goalDuration} days`,
        `Eliminate Debt`,
        `Save for an emergency`,
        `Invest for the future`,
        `Increase your income`
    ],
    bots: {
        "SavingSpree": {
            status: 3.6,
            subscriber: false,
            trial: {
                startDate: "2020-01-19",
                endDate: "2020-03-04"
            },
            checkinTime: "Friday, 10:00:00",
            step: {
                index: 0,
                detail: {
                    amount: 167,
                    startDate: "2020-01-11",
                    endDate: "2020-01-24",
                    stepReminder: true,
                    isComplete: false
                }
            },
            goal: {
                index: 0,
                detail: {
                    saved: 0,
                    amount: 1000,
                    duration: 90,
                    startDate:  "2020-01-04",
                    endDate: "2020-04-03"
                }
            }
        }
    }
};

export default user;