import moment from 'moment';

export function getDaysRemaining(endDate) {
    return moment(endDate).diff(moment(), 'days') + 1;
}

export function getDaysTotal(startDate, endDate) {
    return moment(endDate).diff(moment(startDate), 'days') + 1;
}

export function formatThousands(amount) {
    return Number(amount).toLocaleString('en-US');
}