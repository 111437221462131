import React from 'react';

//import PropTypes from 'prop-types';

class Start extends React.Component {
    goToDashboard = () => {
        console.log("Go to Dashboard!");
    }
    componentDidMount() {
        window.showLandbot();
    }
    
    componentWillUnmount() {
        window.hideLandbot();
    }
    render() {
        return (
            <></>
        )
    }
}

export default Start;