import React from 'react';
import Status from './Status';
import Row from './Row';
import Gauge from './Gauge';
import CurrentStep from './CurrentStep';
import Goal from './Goal';
import { getDaysRemaining, getDaysTotal, formatThousands } from '../helpers';
import base from '../base';
import sampleUser from '../sample-user';

class App extends React.Component {
  state = {
    user: {}
  }
  componentDidMount() {
    const { params } = this.props.match;
    this.ref = base.syncState(`${params.userID}/user`, {
      context: this,
      state: 'user'
    });
  }
  componentWillUnmount() {
    base.removeBinding(this.ref);
  }
  loadSampleData = () => {
    this.setState({
      user: sampleUser
    })
  }
  render() {
    return (
      <>
        <div className="heading">
          <Status level={
              this.state.user.bots !== undefined ?
              this.state.user.bots.SavingSpree.status :
              3.0
            } />
        </div>
        <div className="main">
          <Row title="Stats">
            <Gauge
              value={
                this.state.user.bots !== undefined ?
                getDaysRemaining(this.state.user.bots.SavingSpree.step.detail.endDate) :
                7
              }
              maxValue={
                this.state.user.bots !== undefined ?
                getDaysTotal(this.state.user.bots.SavingSpree.step.detail.startDate,
                  this.state.user.bots.SavingSpree.step.detail.endDate) :
                7
              }
              indicator="ring" label='days left in step' isTimeUrgent={true} />
            <Gauge
              value={
                this.state.user.bots !== undefined ?
                this.state.user.bots.SavingSpree.goal.detail.saved : 
                0
              }
              maxValue={
                this.state.user.bots !== undefined ?
                this.state.user.bots.SavingSpree.goal.detail.amount :
                1000
              }
              indicator="bar" label='total saved' isDollarAmount={true} />
            <Gauge
              value={
                this.state.user.bots !== undefined ?
                getDaysRemaining(this.state.user.bots.SavingSpree.goal.detail.endDate) :
                90
              }
              maxValue={
                this.state.user.bots !== undefined ?
                getDaysTotal(this.state.user.bots.SavingSpree.goal.detail.startDate,
                  this.state.user.bots.SavingSpree.goal.detail.endDate) :
                90
              }
              indicator="ring" label='days till goal' isTimeUrgent={true} />
          </Row>
          <Row title="Current Step">
            <CurrentStep description={
                this.state.user.bots !== undefined ?
                this.state.user.steps[this.state.user.bots.SavingSpree.step.index]
                  .replace('%{payDaySavingsAmount}',
                    '$'+formatThousands(this.state.user.bots.SavingSpree.step.detail.amount)) :
                ""
              } />
          </Row>
          <Row title="Goal">
            <Goal description={
                this.state.user.bots !== undefined ?
                this.state.user.goals[this.state.user.bots.SavingSpree.goal.index]
                  .replace('%{goalAmount}', '$'+formatThousands(this.state.user.bots.SavingSpree.goal.detail.amount))
                  .replace('%{goalDuration}', this.state.user.bots.SavingSpree.goal.detail.duration) :
                ""
              } />
          </Row>
          <button onClick={this.loadSampleData}>Load Sample Data</button>
        </div>
      </>
    );
  }
}

export default App;
